<template>
  <b-form-group
    :label="
      `${
        label === null
          ? null
          : label
          ? translateLabel
            ? $t(label)
            : label
          : $t(varname)
      }:`
    "
    label-class="text-nowrap"
    :label-for="varname"
    :state="errors[varname] ? false : null"
    :invalid-feedback="feedback(errors[varname])"
    :description="$t(description) || null"
  >
    <component
      style="min-width: 100px;"
      :is="tagname"
      :id="varname"
      :name="varname"
      :value="value"
      @input="$emit('input', $event)"
      :state="errors[varname] ? false : null"
      v-bind="$attrs"
    >
      <slot />
    </component>
  </b-form-group>
</template>

<script>
export default {
  props: {
    varname: { type: String },
    tagname: { type: String, default: "b-form-input" },
    value: { type: [String, Number], default: "" },
    label: { type: String, default: "" },
    translateLabel: { type: Boolean, default: true },
    description: { type: String, default: "" },
    errors: { type: Object, default: () => ({}) },
  },
  methods: {
    /** @return {Array} */
    feedback(arr) {
      return arr ? arr.join?.("\n") : null
    },
  },
}
</script>
