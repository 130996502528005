<template>
  <b-overlay :show="loading">
    <b-container class="my-2 my-sm-5 px-0">
      <div class="mb-4">
        <h1>{{ $t("account.title") }}</h1>
        <small class="text-muted">{{ $t("account.subtitle") }}</small>
      </div>

      <b-card class="p-4">
        <b-row>
          <b-col
            cols="12"
            lg="4"
            class="mx-auto px-4 px-lg-3 border-lg-right d-flex flex-column justify-content-center"
            style="max-width: 320px"
          >
            <b-overlay
              :show="avatarHover || avatarLoading"
              rounded="circle"
              class="rounded-circle overflow-hidden"
              v-b-hover="hover => (avatarHover = hover)"
              :title="$t('account.avatar.upload')"
            >
              <template #overlay>
                <div
                  class="p-4 rounded-circle"
                  style="background: rgba(128, 128, 128, 0.2)"
                  v-if="!avatarLoading"
                >
                  <b-icon-camera-fill font-scale="3" />
                </div>
                <b-spinner label="Loading..." v-else />
              </template>

              <b-img
                fluid-grow
                blank-color="rgba(248, 249, 250, 1)"
                :blank="!avatarPath"
                :src="avatarPath || $images.noAvatar"
                @error="
                  event => {
                    event.target.src = $images.noAvatar
                  }
                "
                :alt="$t('account.avatar.alt')"
                style="aspect-ratio: 1/1; object-fit: cover"
              />

              <b-form-file
                id="avatar"
                name="avatar"
                accept="image/jpeg, image/png"
                @input="uploadAvatar"
                plain
                class="position-absolute top w-100 h-100 cursor-pointer"
                style="opacity: 0; top: 0; left: 0; z-index: 15"
                :disabled="avatarLoading"
              >
              </b-form-file>
            </b-overlay>

            <b-button variant="primary" class="p-0 mt-4 text-center mx-auto">
              <label
                for="avatar"
                class="m-0 py-2 px-4 cursor-pointer"
                style="height: 100%;"
              >
                {{ $t("account.avatar.change") }}
              </label>
            </b-button>

            <b-form-invalid-feedback :state="avatarError ? false : null">
              {{ avatarError }}
            </b-form-invalid-feedback>
          </b-col>

          <b-col class="mt-4 mt-sm-0">
            <b-overlay :show="userLoading">
              <b-form @submit.prevent="updateUser">
                <b-row>
                  <b-col>
                    <FormField
                      varname="first_name"
                      v-model="first_name"
                      :errors="userErrors"
                      type="text"
                      required
                    />
                  </b-col>
                  <b-col>
                    <FormField
                      varname="last_name"
                      v-model="last_name"
                      :errors="userErrors"
                      type="text"
                      required
                    />
                  </b-col>
                </b-row>

                <FormField
                  varname="email"
                  v-model="email"
                  :errors="userErrors"
                  type="email"
                  required
                />

                <FormField
                  varname="password"
                  label="new_password"
                  v-model="password"
                  :errors="userErrors"
                  type="password"
                  minlength="6"
                />
                <b-collapse :visible="password.length > 0">
                  <FormField
                    varname="password_confirmation"
                    label="repeat_password"
                    v-model="password_confirmation"
                    :errors="userErrors"
                    type="password"
                    :required="Boolean(password.length)"
                  />
                </b-collapse>

                <b-row cols="1" cols-md="2" cols-xl="3">
                  <b-col>
                    <FormField
                      tagname="b-form-select"
                      varname="birth_year"
                      v-model="birth_year"
                      :errors="userErrors"
                      required
                    >
                      <b-form-select-option
                        v-for="year in $store.getters.years"
                        :key="year"
                        :value="`${year}`"
                      >
                        {{ year }}
                      </b-form-select-option>
                    </FormField>
                  </b-col>

                  <b-col>
                    <FormField
                      tagname="b-form-select"
                      varname="gender"
                      v-model="gender"
                      :errors="userErrors"
                      required
                    >
                      <b-form-select-option
                        selected
                        disabled
                        value=""
                      ></b-form-select-option>
                      <b-form-select-option
                        v-for="gender in $store.getters.genders"
                        :value="gender.value"
                        :key="gender.value"
                      >
                        {{ $t(gender.text) }}
                      </b-form-select-option>
                    </FormField>
                  </b-col>

                  <b-col>
                    <FormField
                      tagname="b-form-select"
                      varname="language"
                      v-model="language"
                      :errors="userErrors"
                      required
                    >
                      <b-form-select-option
                        selected
                        disabled
                        value=""
                      ></b-form-select-option>
                      <b-form-select-option
                        v-for="language in $store.getters.languages"
                        :value="language.code.toUpperCase()"
                        :key="language.code"
                        >{{ language.text }}</b-form-select-option
                      >
                    </FormField>
                  </b-col>
                </b-row>

                <b-form-group>
                  <b-form-checkbox
                    size="lg"
                    id="marketing_consent_flg"
                    name="marketing_consent_flg"
                    v-model="marketing_consent_flg"
                    style="font-size: 1rem;"
                  >
                    {{ $t("register.marketing_consent") }}
                  </b-form-checkbox>
                </b-form-group>

                <b-form-invalid-feedback
                  :state="userError ? false : null"
                  class="mb-3"
                >
                  {{ userError }}
                </b-form-invalid-feedback>

                <b-button block variant="primary" type="submit">{{
                  $t("account.submit")
                }}</b-button>
              </b-form>
            </b-overlay>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </b-overlay>
</template>

<script>
import axios from "axios"
import { mapGetters } from "vuex"
import { BIconCameraFill } from "bootstrap-vue"
import FormField from "@/components/FormField.vue"

export default {
  components: { BIconCameraFill, FormField },

  data() {
    return {
      loading: false,
      avatarPath: this.$images.noAvatar,
      avatarHover: false,
      avatarLoading: false,
      avatarError: "",
      userError: "",
      userErrors: {},
      userLoading: false,
      profilingLoading: false,

      marketing_consent_flg: false,
      initialUser: {},
      first_name: "",
      last_name: "",
      email: "",
      birth_year: "",
      gender: "",
      language: "",
      password: "",
      password_confirmation: "",
    }
  },

  computed: {
    ...mapGetters(["userInfo"]),
  },

  watch: {
    userInfo(data) {
      // Save the unmodified user data
      this.initialUser = data
      // Extract the data and apply it to the form
      const {
        first_name,
        last_name,
        email,
        birth_year,
        gender,
        language,
        avatar_file_path,
        avatar_file_name,
        marketing_consent_flg,
      } = data
      this.avatarPath = `${avatar_file_path}${avatar_file_name}`
      Object.assign(this, {
        first_name,
        last_name,
        email,
        birth_year,
        gender,
        language,
      })
      if (marketing_consent_flg == "Y") {
        this.marketing_consent_flg = true
      } else if (marketing_consent_flg == "N") {
        this.marketing_consent_flg = false
      } else {
        this.marketing_consent_flg = null
      }
      this.$store.dispatch("changeLanguage", language)
      this.loading = false
    },
  },

  created() {
    document.body.ondragover = () => (this.avatarHover = true)
    document.body.ondragend = () => (this.avatarHover = false)

    this.loading = true
    this.$store.dispatch("fetchUserInfo")
  },

  methods: {
    async updateUser() {
      this.userLoading = true
      this.userError = ""
      this.userErrors = {}

      try {
        let newValues = {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          birth_year: this.birth_year,
          gender: this.gender,
          language: this.language,
          marketing_consent_flg: this.marketing_consent_flg,
        }
        if (
          this.marketing_consent_flg !== null ||
          this.marketing_consent_flg !== undefined
        ) {
          newValues.marketing_consent_flg = this.marketing_consent_flg
            ? "Y"
            : "N"
        }
        if (this.password == this.password_confirmation) {
          // Only send passwords if they match
          Object.assign(newValues, {
            password: this.password,
            password_confirmation: this.password_confirmation,
          })
        } else {
          this.userError = this.$t("repeat_password-error")
          return false
        }

        // Filter out unchanged values
        const changed = Object.fromEntries(
          Object.entries(newValues).filter(([key, val]) =>
            key in this.initialUser ? this.initialUser[key] != val : val
          )
        )

        // If there are changed values
        if (Object.keys(changed).length) {
          await axios.put("/user", changed)
          if (changed.language) {
            this.$store.dispatch("changeLanguage", changed.language)
          }
          this.password = ""
          this.password_confirmation = ""
          // Update the unmodified user data with the changes
          Object.assign(this.initialUser, changed)
        }
      } catch ({ name, message, response }) {
        const { error_code, error_details, error_message } = response.data
        if (error_code && error_details && error_message) {
          // Show specific error on each input
          this.userErrors = error_details
          this.userError =
            `${error_message}:` +
            Object.values(error_details).map(val => `\n${val.join("\n")}`)
        } else {
          this.userError = `${name}: ${message}`
        }
      } finally {
        this.userLoading = false
      }
    },

    async uploadAvatar(file) {
      this.avatarError = ""
      this.avatarLoading = true

      try {
        if (!file) {
          this.avatarError = this.$t("account.avatar.no_file")
          return false
        }

        const response = await axios.post("/mediaobject", {
          filename: file.name,
          context_type: "AVT",
        })
        // Extract response
        const { filepath, filename, save_url, media_file_id } =
          response.data?.data ?? {}

        if (filepath && filename && save_url) {
          try {
            // Delete global Authorization header for aws requests
            const aws = axios.create()
            aws.interceptors.request.use(config => {
              delete config.headers.common.Authorization
              return config
            })

            await aws.put(save_url, file)
            this.avatarPath = `${filepath}${filename}`
            await axios.put("/user", { media_file_id })
            this.$store.dispatch("fetchUserInfo")
          } catch ({ name, message, response = null }) {
            if (response) {
              this.avatarError = `${this.$t(
                "account.avatar.upload-error"
              )}: ${JSON.stringify(response.data)}`
            } else {
              this.avatarError = `${this.$t(
                "account.avatar.upload-error"
              )}: ${name}, ${message}`
            }
          }
        }
      } catch ({ name, message, response }) {
        const { error_code, error_details, error_message } = response.data
        if (error_code && error_details && error_message) {
          this.avatarError =
            `${error_message}:` +
            Object.entries(error_details).map(
              ([key, val]) => `\n${key} - ${val}`
            )
        } else {
          this.avatarError = `${name}: ${message}`
        }
      } finally {
        this.avatarLoading = false
      }
    },
  },
}
</script>
